import initComponents from "@unodc/standard-jslib/dist/components/index"

import "./styles"

window.addEventListener('DOMContentLoaded', () => {
    initComponents()

    // Init React
    const reactContainer = document.querySelector('div[data-component-type="react"]');
    if (reactContainer !== null ) {
        import(/* webpackChunkName: "react-lazy" */ "@unodc/standard-jslib/dist/components/react").then(() => {
            import(/* webpackChunkName: "react-lazy" */ "./components/react").then((localPortals) => {
                localPortals.default(document.querySelector('div#react-root'))
            })

        });
    }
})